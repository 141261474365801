var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Navigation'),_c('main',{staticClass:"mt-5"},[_c('section',{staticClass:"main-body"},[_c('div',{staticClass:"form-card"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return _c('form',{on:{"submit":function($event){$event.preventDefault();validate().then(_vm.handleRegister)}}},[_c('div',{staticClass:"text-center"},[_c('h3',{staticClass:"text-info p-0 mt-3 mb-1"},[_vm._v("Signup")])]),_c('div',{staticClass:"form-group m-0 justify-content-center",staticStyle:{"width":"100%"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"username"}}),_c('ValidationProvider',{attrs:{"rules":"required","name":"firstname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group text-left"},[_c('label',{staticClass:"form-label pl-1",attrs:{"for":"firstname"}},[_vm._v("Firstname")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.firstname),expression:"form.firstname"}],staticClass:"form-control item field",attrs:{"placeholder":"e.g Micheal","state":errors[0] ? false : valid ? true : null,"name":"firstname","type":"name","id":"firstname"},domProps:{"value":(_vm.form.firstname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "firstname", $event.target.value)}}}),_c('small',{staticClass:"text-danger text-left",staticStyle:{"padding-left":"10px","margin-bottom":"10px"}},[_vm._v(_vm._s(errors[0])+" ")])])}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"lastname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group text-left"},[_c('label',{staticClass:"form-label pl-1",attrs:{"for":"lastname"}},[_vm._v("Lastname")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastname),expression:"form.lastname"}],staticClass:"form-control item field",attrs:{"placeholder":"e.g Smith","state":errors[0] ? false : valid ? true : null,"name":"lastname","type":"name","id":"name"},domProps:{"value":(_vm.form.lastname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "lastname", $event.target.value)}}}),_c('small',{staticClass:"text-danger text-left",staticStyle:{"padding-left":"10px","margin-bottom":"10px"}},[_vm._v(_vm._s(errors[0])+" ")])])}}],null,true)})],1),_c('ValidationProvider',{attrs:{"rules":"required","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group text-left"},[_c('label',{staticClass:"form-label pl-1",attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control item field",attrs:{"placeholder":"Email","state":errors[0] ? false : valid ? true : null,"name":"email","type":"email","id":"email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('small',{staticClass:"text-danger text-left",staticStyle:{"padding-left":"10px","margin-bottom":"10px"}},[_vm._v(_vm._s(errors[0])+" ")])])}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group text-left"},[_c('label',{staticClass:"form-label pl-1",attrs:{"for":"gender"}},[_vm._v("Gender")]),_c('b-form-select',{staticClass:"form-control item field",attrs:{"id":"gender","aria-placeholder":"Gender","state":errors[0] ? false : valid ? true : null,"options":_vm.genderOptions},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}}),_c('small',{staticClass:"text-danger text-left",staticStyle:{"padding-left":"10px","margin-bottom":"10px"}},[_vm._v(_vm._s(errors[0])+" ")])],1)}}],null,true)},[_vm._v(" \" name=\"gender\"> ")]),_c('ValidationProvider',{attrs:{"rules":"required|integer","name":"Age"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group text-left"},[_c('label',{staticClass:"form-label pl-1",attrs:{"for":"age"}},[_vm._v("Age")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.age),expression:"form.age"}],staticClass:"form-control item field",attrs:{"placeholder":"e.g 30","state":errors[0] ? false : valid ? true : null,"name":"age","type":"age","id":"age"},domProps:{"value":(_vm.form.age)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "age", $event.target.value)}}}),_c('small',{staticClass:"text-danger text-left",staticStyle:{"padding-left":"10px","margin-bottom":"10px"}},[_vm._v(_vm._s(errors[0])+" ")])])}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group text-left"},[_c('label',{staticClass:"form-label pl-1",attrs:{"for":"country"}},[_vm._v(" Country")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.country),expression:"form.country"}],staticClass:"form-control item field",attrs:{"placeholder":"e.g Uganda","state":errors[0] ? false : valid ? true : null,"name":"country","type":"country","id":"country"},domProps:{"value":(_vm.form.country)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "country", $event.target.value)}}}),_c('small',{staticClass:"text-danger text-left",staticStyle:{"padding-left":"10px","margin-bottom":"10px"}},[_vm._v(_vm._s(errors[0])+" ")])])}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"City"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group text-left"},[_c('label',{staticClass:"form-label pl-1",attrs:{"for":"city"}},[_vm._v("City/District")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.city),expression:"form.city"}],staticClass:"form-control item field",attrs:{"placeholder":"e.g Kampala","state":errors[0] ? false : valid ? true : null,"name":"city","type":"city","id":"city"},domProps:{"value":(_vm.form.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "city", $event.target.value)}}}),_c('small',{staticClass:"text-danger text-left",staticStyle:{"padding-left":"10px","margin-bottom":"10px"}},[_vm._v(_vm._s(errors[0])+" ")])])}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Street"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group text-left"},[_c('label',{staticClass:"form-label pl-1",attrs:{"for":"street"}},[_vm._v("Street")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.street),expression:"form.street"}],staticClass:"form-control item field",attrs:{"placeholder":"e.g Kampala Road","state":errors[0] ? false : valid ? true : null,"name":"street","type":"street","id":"street"},domProps:{"value":(_vm.form.street)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "street", $event.target.value)}}}),_c('small',{staticClass:"text-danger text-left",staticStyle:{"padding-left":"10px","margin-bottom":"10px"}},[_vm._v(_vm._s(errors[0])+" ")])])}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|integer","name":"Experience"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group text-left"},[_c('label',{staticClass:"form-label pl-1",attrs:{"for":"experience"}},[_vm._v("Work experience (in years)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.experience),expression:"form.experience"}],staticClass:"form-control item field",attrs:{"placeholder":"e.g 4","state":errors[0] ? false : valid ? true : null,"name":"experience","type":"experience","id":"experience"},domProps:{"value":(_vm.form.experience)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "experience", $event.target.value)}}}),_c('small',{staticClass:"text-danger text-left",staticStyle:{"padding-left":"10px","margin-bottom":"10px"}},[_vm._v(_vm._s(errors[0])+" ")])])}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group text-left"},[_c('label',{staticClass:"form-label pl-1",attrs:{"for":"email"}},[_vm._v("Job Description")]),_c('b-form-textarea',{staticClass:"form-control item field",attrs:{"placeholder":"Job Description","state":errors[0] ? false : valid ? true : null,"name":"description","rows":"3","id":"description"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger text-left",staticStyle:{"padding-left":"10px","margin-bottom":"10px"}},[_vm._v(_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group text-left",attrs:{"label":"Password"}},[_c('label',{staticClass:"form-label pl-1",attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control item field",attrs:{"placeholder":"password","state":errors[0] ? false : valid ? true : null,"name":"password","type":"password","id":"password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('small',{staticClass:"text-danger text-left",staticStyle:{"padding-left":"10px","margin-bottom":"10px"}},[_vm._v(_vm._s(errors[0])+" ")])])}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","name":"Confirm Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group text-left",attrs:{"label":"Confirm Password"}},[_c('label',{staticClass:"form-label pl-1",attrs:{"for":"password-confirm"}},[_vm._v("Confirm password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.passwordConfirm),expression:"form.passwordConfirm"}],staticClass:"form-control item field",attrs:{"placeholder":"password","state":errors[0] ? false : valid ? true : null,"name":"password","type":"password","id":"password-confirm"},domProps:{"value":(_vm.form.passwordConfirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "passwordConfirm", $event.target.value)}}}),_c('small',{staticClass:"text-danger text-left",staticStyle:{"padding-left":"10px","margin-bottom":"10px"}},[_vm._v(_vm._s(errors[0])+" ")])])}}],null,true)}),_c('div',{staticClass:"buttons"},[_c('div',{staticClass:"login"},[_c('button',{staticClass:"btn btn-info login",attrs:{"type":"submit"},on:{"click":_vm.handleRegister}},[_vm._v(" Create Account ")])])])],1)}}])})],1),(_vm.registrationResponse)?_c('p',[_vm._v(_vm._s(_vm.registrationResponse))]):_vm._e(),(_vm.registrationError)?_c('p',{staticClass:"text-danger"},[_vm._v(" Login failed: "+_vm._s(_vm.registrationError)+" ")]):_vm._e(),_c('p',[_vm._v(" Already have an account? "),_c('router-link',{staticClass:"text-info",attrs:{"to":"/login"}},[_vm._v("Login")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }